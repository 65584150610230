/* eslint-disable react/jsx-no-target-blank */
import { BodySecondary, FaIcon, GrayColor } from '@web/atomic';
import { InlineBlockStyled } from '@web/atomic/obj.wrappers';
import { sendDataLayer } from '@web/utils/analytics';
import { hasNavigator } from '@web/utils/platform';
import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { getShareLinks, ShareIconsProps } from './share-icons-cell.utils';

export interface ShareIconsNewsCellProps extends ShareIconsProps {
  identification: string;
  shareTyping?: string;
  kind?: 'default' | 'light' | 'dark';
}

export const ShareIconsNewsCell: React.FunctionComponent<ShareIconsNewsCellProps> = (props) => {
  const isNativeShareEnabled = hasNavigator() && (navigator as any).share;
  const onNativeShareClick = () => {
    sendDataLayer({
      event: 'custom_click',
      id: `ga-share-native-${props.identification}`,
    });

    const nav: any = navigator;
    // https://web.dev/web-share/
    if (nav.share) {
      const { title, text, url } = props;
      nav.share({ title, text, url });
    } else {
      console.error('nav.share not available');
    }
  };

  const shareLinks = getShareLinks(props);
  const themeContext = useContext(ThemeContext);
  const color = props.kind === 'light' || (themeContext.name === 'dark' && props.kind !== 'dark') ? GrayColor.White : GrayColor.GrayXDark;
  return (
    <>
      <InlineBlockStyled onClick={onNativeShareClick}>
        <BodySecondary kind={props.kind === 'light' ? 'light' : undefined}>{props.shareTyping}</BodySecondary>
      </InlineBlockStyled>
      <InlineBlockStyled>
        <a
          href={shareLinks.email}
          id={`ga-share-email-${props.identification}`}
          role="button"
          aria-label="email share"
          target="_blank"
          rel="noopener"
        >
          <FaIcon.Envelope size="lg" color={color} />
        </a>
      </InlineBlockStyled>
      <InlineBlockStyled>
        <a
          href={shareLinks.facebook}
          id={`ga-share-facebook-${props.identification}`}
          role="button"
          aria-label="facebook share"
          target="_blank"
          rel="noopener"
        >
          <FaIcon.Facebook size="lg" color={color} />
        </a>
      </InlineBlockStyled>
      <InlineBlockStyled>
        <a
          href={shareLinks.linkedin}
          id={`ga-share-linkedin-${props.identification}`}
          role="button"
          aria-label="linkedin share"
          target="_blank"
          rel="noopener"
        >
          <FaIcon.Linkedin size="lg" color={color} />
        </a>
      </InlineBlockStyled>
      <InlineBlockStyled>
        <a
          href={shareLinks.twitter}
          id={`ga-share-twitter-${props.identification}`}
          role="button"
          aria-label="twitter share"
          target="_blank"
          rel="noopener"
        >
          <FaIcon.Twitter size="lg" color={color} />
        </a>
      </InlineBlockStyled>
      <InlineBlockStyled>
        <a
          href={shareLinks.whatsapp}
          id={`ga-share-whatsapp-${props.identification}`}
          role="button"
          aria-label="whatsapp share"
          target="_blank"
          rel="noopener"
        >
          <FaIcon.Whatsapp size="lg" color={color} />
        </a>
      </InlineBlockStyled>
      {isNativeShareEnabled && (
        <InlineBlockStyled>
          <FaIcon.Share onClick={onNativeShareClick} size="lg" color={color} />
        </InlineBlockStyled>
      )}
    </>
  );
};

ShareIconsNewsCell.defaultProps = {
  shareTyping: 'Compartilhar',
  kind: 'default',
};
