import { PostRedeemMiniCourseUrl, RedeemMiniCourseRequest } from '@global/utils/api/coupon.controller.model';
import SvgImgSoniaDown from '@web/atomic/atm.svg-icon/img-sonia-down';
import { PageProps } from '@lp-root/src/utils/local-types';
import { Body, Button, Col, FaIcon, Grid, H3, InputLabel, LargeSeparator, Row, Separator } from '@web/atomic';
import { FullScreenPrimaryBackground } from '@web/atomic/atm.background';
import { TextField } from '@web/atomic/legacy/atm.text-field';
import { ForcedFade } from '@web/atomic/legacy/obj.animation/animation.component.style';
import flashDispatcherService from '@web/atomic/legacy/obj.flash-wrapper/flash-dispatcher.service';
import { Form, FormData, Validators } from '@web/atomic/legacy/obj.form';
import FormWrapperWithHeader from '@web/atomic/mol.form-wrapper-with-header/form-wrapper-with-header.component';
import { useQueryParams } from '@web/atomic/obj.custom-hooks/query-params';
import { usePostUsingNoAuth } from '@web/data/use-post-with-no-auth';
import { graphql } from 'gatsby';
import * as React from 'react';
import LargeSeparatorRow from '../components/atm.large-separator-row/large-separator-row.component';
import { ShareIconsNewsCell } from '../components/mol.share-icons-cell/share-icons-cell.component';
import CleanLayout from '../components/org.layout/clean-layout.component';
import { CustomQuery } from './quiz-b2b';
import { ErrorName } from '@global/utils/api/error-name';

const ResgateMiniCursoPage: React.FunctionComponent<PageProps<unknown, CustomQuery>> = (props) => {
  const queryParams = useQueryParams<RedeemMiniCourseRequest>();

  const handleError = (error) => {
    console.error('ERROR: regate-mini-curso.tsx ~ line 56 ~ handleError ~ error', error);
    const message =
      error?.data?.name === ErrorName.NotFoundCouponError
        ? 'Código inválido.'
        : 'Ocorreu um erro ao enviar seus dados. Tente novamente mais tarde.';
    flashDispatcherService.dispatchMessage(message, 'alert');
  };
  const [subscribeToMiniCourse, subscribeRequestState] = usePostUsingNoAuth<RedeemMiniCourseRequest>({
    url: PostRedeemMiniCourseUrl,
    onError: handleError,
  });
  const handleSubmit = async (formData: FormData<ResgateMiniCursoFormData>) => {
    if (Object.keys(formData.error).length !== 0) return;
    const request: RedeemMiniCourseRequest = {
      code: formData.data.code,
      email: formData.data.email,
      company: queryParams.company,
    };
    subscribeToMiniCourse(request);
  };

  const finished = !!subscribeRequestState.data?.message;

  return (
    <CleanLayout>
      <FullScreenPrimaryBackground>
        <Grid>
          <Row>
            <Col xs>
              <LargeSeparator />
            </Col>
          </Row>
          <Row center="xs">
            <FormWrapperWithHeader title="Minicursa Vigilantes do Sono">
              {finished && <H3>{subscribeRequestState.data?.message}</H3>}

              {!finished && (
                <Row center="xs" mt>
                  <Col xs={12}>
                    <Body>
                      Use seu e-mail e seu código para começar a receber as pílulas de conhecimento{' '}
                      <span role="img" aria-label="cérebro">
                        🧠
                      </span>
                    </Body>
                    <ResgateMiniCursoForm
                      onSubmit={handleSubmit}
                      submitLoading={subscribeRequestState.loading}
                      initialEmail={queryParams.email}
                      initialCode={queryParams.code}
                    />
                  </Col>
                </Row>
              )}
            </FormWrapperWithHeader>
          </Row>

          {finished && <ShareFooter {...props} />}
        </Grid>
      </FullScreenPrimaryBackground>
    </CleanLayout>
  );
};

export default ResgateMiniCursoPage;

export const query = graphql`
  query ResgateMiniCursoPageQuery {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;

////////////////////////////////////////////////////////////////////////////////////////////////////

interface ResgateMiniCursoFormData {
  email: string;
  code: string;
}

interface IResgateMiniCursoFormProps {
  onSubmit: (formData: FormData<ResgateMiniCursoFormData>) => any;
  submitLoading: boolean;
  initialEmail: string;
  initialCode: string;
}

const ResgateMiniCursoForm: React.FunctionComponent<IResgateMiniCursoFormProps> = (props) => {
  return (
    <Form onSubmit={props.onSubmit}>
      <Row mt mb>
        <Col xs={12}>
          <Form.Field
            name={'email' as keyof ResgateMiniCursoFormData}
            initialValue={props.initialEmail}
            key={props.initialEmail}
            validators={[Validators.Required(), Validators.EmailRegex('O e-mail digitado é inválido.')]}
          >
            <InputLabel>E-mail</InputLabel>
            <TextField type={'email'} autoComplete="email" />
          </Form.Field>
          <Separator />
        </Col>
      </Row>
      <Row mt mb>
        <Col xs={12}>
          <Form.Field
            name={'code' as keyof ResgateMiniCursoFormData}
            initialValue={props.initialCode}
            key={props.initialCode}
            validators={[Validators.Required()]}
          >
            <InputLabel
              role="tooltip"
              data-microtip-position="right"
              data-microtip-size="medium"
              aria-label={`Esse é o código para desbloquear o minicurso pra você. Você o recebeu no seu e-mail.`}
            >
              Código <FaIcon.Question />
            </InputLabel>
            <TextField type={'text'} />
          </Form.Field>
          <Separator />
        </Col>
      </Row>
      <Row mt mb>
        <Col xs={12}>
          <Button type="submit" kind="primary" loading={props.submitLoading} expanded>
            Cadastrar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

////////////////////////////////////////////////////////////////////////////////////////////////////

const ShareFooter: React.FunctionComponent<PageProps<unknown, CustomQuery>> = (props) => {
  return (
    <>
      <Row mt>
        <Col xs>
          <LargeSeparator />
        </Col>
      </Row>
      <Row mt>
        <Col xs>
          <ForcedFade show={true}>
            <Row center={'xs'} mt>
              <Col xs={8} sm={5}>
                <Body kind="light" center>
                  Conhece alguém com dificuldade para dormir?{' '}
                </Body>
              </Col>
            </Row>
            <Row center={'xs'} mt>
              <ShareIconsNewsCell
                kind="light"
                identification="order-success"
                shareTyping={'Compartilhe: '}
                title="Vigilantes do Sono"
                url={props.data.site.siteMetadata.siteUrl}
                text={'Conheça o programa sem remédios que melhora o sono de 4 a cada 5 pessoas.'}
              />
            </Row>
            <Row center={'xs'} mt>
              <Col xs={8} sm={5} md={4} lg={3}>
                <SvgImgSoniaDown />
              </Col>
            </Row>
            <LargeSeparatorRow />
          </ForcedFade>
        </Col>
      </Row>
    </>
  );
};
