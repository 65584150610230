import * as React from 'react';

const SvgImgSoniaDown = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg viewBox="0 0 244 189" {...props}>
    <g transform="translate(0 1)" fill="none" fillRule="evenodd">
      <ellipse fill="#05103D" cx={108.9} cy={141.3} rx={108.9} ry={45.9} />
      <g className="shake-animation-on-hover">
        <path
          d="M21.6 120.403c1.24 17.349 7.44 29.741 18.602 37.176 16.741 11.153 53.945 26.024 107.89 14.871s48.365-39.035 53.945-52.047c5.58-13.012 20.462-31.6 35.343-39.035 14.882-7.435-1.86-22.306-26.042-14.87-24.182 7.435-39.064 18.587-57.665 22.305-18.602 3.718-50.225 1.859-59.526-13.012-9.301-14.87 11.16-29.74-7.44-59.482-18.603-29.741-22.323-11.153-26.043-3.718-3.72 7.436 0 20.447-13.022 24.165C34.622 40.474 21.6 81.368 21.6 101.815v18.588z"
          stroke="#B78160"
          strokeWidth={1.8}
          fill="#B78160"
        />
        <path
          d="M19.8 120.6c1.274 17.288 7.645 29.637 19.112 37.046 17.201 11.113 55.425 25.932 110.85 14.818 27.848-5.584 40.257-15.377 46.438-25.383"
          stroke="#05103D"
          strokeWidth={3.6}
          strokeLinecap="round"
        />
        <path
          d="M223.011 75.192c6.45-1.135 11.36-4.29 10.967-7.048-.393-2.757-5.94-4.071-12.39-2.936-6.449 1.135-11.359 4.29-10.966 7.048.393 2.757 5.94 4.071 12.39 2.936zM76.068 21.443c2.896 5.283 7.453 8.427 10.177 7.022 2.724-1.404 2.584-6.825-.313-12.108-2.896-5.283-7.453-8.427-10.177-7.022-2.724 1.404-2.584 6.825.313 12.108z"
          fill="#ECD8B4"
        />
        <ellipse fill="#ECD8B4" transform="rotate(82 70.473 8.848)" cx={70.473} cy={8.848} rx={3.707} ry={1.855} />
        <ellipse fill="#ECD8B4" transform="rotate(82 76.165 4.206)" cx={76.165} cy={4.206} rx={2.78} ry={1.855} />
        <ellipse fill="#ECD8B4" transform="rotate(-9 240.164 71.866)" cx={240.164} cy={71.866} rx={2.782} ry={1.853} />
        <ellipse fill="#ECD8B4" transform="rotate(-9 232.745 78.353)" cx={232.745} cy={78.353} rx={4.636} ry={2.78} />
        <circle fill="#ECD8B4" cx={99.9} cy={114.3} r={13.5} />
      </g>
    </g>
  </svg>
);

export default SvgImgSoniaDown;
