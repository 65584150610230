export interface ShareIconsProps {
  title: string;
  text: string;
  url: string;
}

export const getShareLinks = (props: ShareIconsProps) => {
  const fbAppId = process.env.GATSBY_FB_CHATFUEL_APP_ID;
  return {
    raw: props,
    facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(props.url)}`,
    email: `mailto:?subject=${props.title}&body=${encodeURIComponent(`${props.text}\n${props.url}`)}/`,
    linkedin: `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(props.url)}&title=${encodeURIComponent(
      props.title
    )}&summary=${encodeURIComponent(props.text)}&source=${encodeURIComponent(`Vigilantes do Sono`)}`,
    twitter: `${encodeURI(`https://twitter.com/intent/tweet?text=${props.text}`)}&url=${encodeURIComponent(props.url)}`,
    whatsapp: `https://api.whatsapp.com/send?text=${encodeURIComponent(`${props.text}\n${props.url}`)}`,
    messenger: `fb-messenger://share?link=${encodeURIComponent(props.url)}&text=${encodeURIComponent(
      props.text
    )}&app_id=${encodeURIComponent(fbAppId)}`,
  };
};
